<template>
  <div class="fwzx">
    <Headbar title="社区中心"></Headbar>

    <div class="title">代理级别</div>
    <div class="input-wrap" @click="show = true">
      <div v-if="level" class="select-label" @click="show = true">{{ level.name }}</div>
      <div style="color: #BBBBBB;" v-else>请选择级别</div>
    </div>
    <div class="title">附言</div>
    <div class="input-wrap">
      <q-input borderless v-model="bz" dense :input-style="{ color: '#fff' }" style="width: 100%;" placeholder="可不填" />
    </div>

    <div class="m-btn q-mt-lg" @click="submit">申请</div>

    <div class="apply" v-if="apply">
      {{ apply }}
    </div>

    <div class="cp-popup">
      <van-popup v-model:show="show" position="bottom">
        <van-picker :confirm-button-text="$t('确定')" :cancel-button-text="$t('取消')" :columns="levelList"
          @confirm="onConfirm" @cancel="show = false" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Picker, Popup } from 'vant';
import CustomComponent from "@/components/CustomComponent";

export default {
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  setup() {
    return {
      us: ref(null),
      levelList: ref([]),
      level: ref(null),
      bz: ref(""),
      show: ref(false),
      apply: ref()
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getLevel()
    this.getdata()
  },
  methods: {
    onConfirm(e) {
      this.level = e
      this.show = false
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "Api/UsersFwzxApply/Get",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 100) {
            let fw = res.data.data
            if (fw) {
              _this.apply =
                fw.bdlevelName +
                "申请  " +
                fw.bdsheng +
                fw.bdshi +
                fw.bdxian +
                fw.bdaddress +
                "     " +
                fw.ispayName;
            }
          }
        }
      )
    },
    getLevel() {
      let _this = this
      _this.$request.post(
        "Api/Level/GetBdlevelList",
        {},
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            data = data.filter(item => item.display && item.number > 0)
            data.forEach(item => item.text = item.name)
            if (data.length > 0) {
              _this.level = data[0]
            }
            _this.levelList = data
          }
        }
      )
    },
    submit() {
      let _this = this
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要申请吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        _this.$request.post(
          "api/UsersFwzxApply/Add",
          {
            token: _this.$utils.getloc("token"),
            userid: _this.us.userid,
            bdlevel: _this.level.number,
            beizhu: _this.bz,
          },
          (res) => {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            if (res.data.code == 100) {
              _this.getdata()
            }
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.fwzx {
  padding: 10px 20px;
}

.title {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #1B1B1B;
}

.select-label {
  font-weight: bold;
  color: #e7ba47;
}

.apply {
  margin-top: 20px;
  padding: 15px;
  color: #BBBBBB;
  border-radius: 6px;
  background-color: #1B1B1B;
}
</style>